import React, { useEffect } from 'react'
import classes from './style/questioner.module.css'
import CustomQuestionerForm from '../components/CustomQuestionerForm'
import Instructions from '../components/Instructions'
import { useState } from 'react'

const CustomQuestioner = ()=>{
    const [code, setCode] = useState("");

    function extractIdFromUrl(url) {
        // Use URL object for parsing
        const parsedUrl = new URL(url);
      
        // Extract the pathname (path after the domain)
        const pathname = parsedUrl.pathname;
      
        // Split the pathname by "/" to get individual parts
        const pathSegments = pathname.split("/");
        return pathSegments[pathSegments.length - 1];
      }

     
    useEffect(()=>{
        setCode(extractIdFromUrl(window.location.href));
    },[])

    const [start,setstart]=useState(0);

    const clickhandler =()=> {
        setstart(1);
    }

    const pclickhandler =()=> {
        setstart(0);
    }

    return(
        <div className={start?classes.body1:classes.body}>
            {start?<CustomQuestionerForm prevClick={pclickhandler} code={code}/>:<Instructions click={clickhandler}/>}
        </div>
    );
}

export default CustomQuestioner