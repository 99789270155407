import React from 'react'
import classes from './style/fullprofile.module.css'
import EditIcon from '@mui/icons-material/Edit';
import ErrorIcon from '@mui/icons-material/Error';
import { useState ,useEffect} from "react";
import Certificates from './Certificates';
import Internships from './Internships';
import Workexperience from './Workexperience';
import Skills from './Skills';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';
import LaunchIcon from '@mui/icons-material/Launch';
import Addpic from './Addpic';
import { NavLink } from 'react-router-dom';

const FullProfile = (props) => {
  const [addPic,setAddPic]=useState(false)
  // const[spinner,setSpinner] = useState(true)
  //   function delay(time) {
  //       return new Promise(resolve => setTimeout(resolve, time));
  //     }
    
  //     async function fix() {
  //       await delay(1000);
  //       setSpinner(false)
        
  //     }
  //     useEffect(()=>{
       
  //       fix()
     
  //   })
    
  const closePop = ()=>{
    setAddPic(false)
  }

  const InfoToolTip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'rgb(69, 83, 175)',
      maxWidth: 620,
      fontSize: theme.typography.pxToRem(18),
      border: '1px solid #dadde9',
    },
  }));
  
  function mapCert(cert)
  {
    return(
      <Certificates
      key={cert._id} 
      name={cert.name}
      body={cert.body}
      />
    )
  }

  function mapIntern(intern)
  {
    return(
      <Internships
        key={intern._id} 
        position={intern.position}
        place={intern.place}
        startDate={formatDate(intern.startDate)}
        endDate={formatDate(intern.endDate)}
        description={intern.description}
      />
    )
  }
  
  function jobmap(job)
  {

    return(
      <Workexperience
        key={job._id} 
        company={job.jobtitle}
        role={job.role}
        hiringbody={job.hiringbody}
        startDate={formatDate(job.startDate)}
        endDate={formatDate(job.endDate)}
        description={job.description}
      />
    )
  }

  function skillmap(skills)
  {

    return(
      <Skills
      key={skills.name}
      skill={skills.name}
      />
      
    )
  }


  function formatDate(date)
  {
    date=date.slice(0,10)
    const [year, month, day] =  date.split('-')
    return(day+"-"+month+"-"+year)
  }

  return (
    <div className={classes.fullprofile}>
      {
        addPic && 
        <Addpic type={"user"} closePop={closePop}/>
      }
      <div style={{ width: "fit-content", height: "fit-content" }}>
        <div  >
        {props.view?null:
          <InfoToolTip
            placement={"right"}
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
            title={
              <React.Fragment>
                <Typography fontSize={18} color="inherit">
                  Note That You Must Complete Your Profile So Companies Can Find You
                </Typography>
              </React.Fragment>
            }
          >
            <ErrorIcon className={classes.badge} style={{ fontSize: 60 }} />
          </InfoToolTip>}
        </div>
      </div>
      
      <div className={classes.maininfo}>
        <div className={classes.userinfoContainer}>
          <div className={classes.userInfo}>
            <div className={classes.profimage}>
              <div className={classes.profileImage}>
                <img src={props.pic} alt="Profile Pic"/>
              </div>
              {props.view?null:
              <div className={classes.fieldContainer}>
                    <button onClick={()=>setAddPic(true)}><p>+</p></button>
              </div>}
          </div>
            <div className={classes.info}>
              <div className={classes.username}>{props.userName}</div>
              <div className={classes.location}>{props.location}</div>
              <br/>
              {props.view&&props.iRes2!=null?null:<a className={classes.location} href={"https://www.matchmaker-eg.com/viewProfile/"+sessionStorage.getItem("ID")} target="_blank">Profile sharable Link <LaunchIcon style={{fontSize:"20"}}/></a>}
            </div>
          </div>
          {props.view?null:
          <div >
            <NavLink to='/EditUserInfo'><EditIcon  style={{ fontSize: 25 }}/>
            Edit
            </NavLink>
          </div>}
        </div>
        <div className={classes.lowersectioninfo}>
          <div className={classes.pinfo}>Personal Info
            <div className={classes.PersonalInfo}>
              <div className={classes.bdate}>Date Of Birth
                <input disabled value={formatDate(props.DateOfBirth)}  />
              </div>
              <div className={classes.bdate}>National ID Number
                <input disabled value={props.NationalID}  />
              </div>
            </div>
          </div>
          <div className={classes.pinfo}>Contact Info
            <div className={classes.PersonalInfo}>
              <div className={classes.bdate}>Email
                <input disabled value={props.Email}  />
              </div>
              <div className={classes.bdate}>Mobile Phone
                <input disabled value={props.mobile}  />
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className={classes.maininfo}>
        <div className={classes.education}>Education</div>
        <div className={classes.subeducation}>
          <label>Highest level of Education:  </label>
          <input disabled value={props.HLOfEdu} />
        </div>
        <div className={classes.subeducation}>
          <label>Faculty:  </label>
          <input disabled value={props.otherf?props.otherf:props.Faculty} />
        </div>
      </div>


      <div className={classes.maininfo}>
        <div className={classes.certIntern}>
          <div className={classes.certificates}>
            <h2>Certificates</h2>
            {props.certificates?props.certificates.map(mapCert):null}
          </div>
          <div className={classes.Internships}><h2>Internships</h2>
          {props.internships?props.internships.map(mapIntern):null}
          </div>
        </div>
      </div>

      <div className={classes.maininfo}>
        <div className={classes.mainexperience}>Work Experience</div>
        <div className={classes.work}>
          <div className={classes.workinfo}><b>Years Of Experience:</b>  {props.yearsOfExp}</div>
          <div className={classes.workinfo}><b>Professional fields or Industries:</b> {props.jobPosition}</div>
          <div className={classes.workinfo}><b>CV Atattchment :</b> <a href={props.CV} target="_blank"> CV Link</a></div>
          {props.job?props.job.map(jobmap):null}
        </div>
      </div>

      <div className={classes.maininfo}>
      {
        <div className={classes.skills}>
          <div className={classes.skillexperience}>General Skills</div>

          {props.generalSkills?props.generalSkills.map(skillmap):null}  

        </div>
      }
        <div className={classes.skills}>
          <div className={classes.skillexperience}>Technical Skills</div>
          {props.technicalSkills?props.technicalSkills.map(skillmap):null}
        </div>
        <div className={classes.skillexperience}>Long Term Goals</div>
        <div className={classes.skillstext}>{props.longTermGoals}</div>
        <div className={classes.skillexperience}>What Motivates Me At Work</div>
        <div className={classes.skillstext}>{props.Motivation}</div>

      </div>
    </div>
  )
  
}

export default FullProfile