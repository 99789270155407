import React from 'react'
import Card from '../components/UI/Card';
import classes from './style/AboutISI.module.css'
import imga from "../Img/avatars/accomplisher.jpg"
import imgb from "../Img/avatars/DemonstrativeMind.jpg"
import imgc from "../Img/avatars/Factual.jpg"
import imgd from "../Img/avatars/Foreseeing.jpg"
import imge from "../Img/avatars/Theorist.jpg"
import imgf from "../Img/avatars/Selfreflective.jpg"
import imgg from "../Img/avatars/planner.jpg"
import imgh from "../Img/avatars/Motivator.jpg"
const AboutISI = ()=>{
    return(
        <div className={classes.container}>
            <div className={classes.title}>
                <h1> ISI </h1>
            </div>
            <div className={classes.comp}>
                <h2> The Intellectual Style Inventory </h2>
                <p> The Intellectual Style Inventory (ISI) is a personality/learning style assessment tool that perfectly serves in talent management and talent acquisition. By answering few simple questions you can learn a lot about your talents, interests and abilities. For employers, it’s the magic stick that explores the intrinsic power of the human capital and for talents it is the self-awareness tool that could be the turning point in their career life. </p>
                <div className={classes.lower}>
                <div className={classes.left}>
                        <h3>The ISI identifies</h3>
                        <p>-Four leadership/ management styles; the Demonstrative Mind (strategic and abstract), the Motivator (people oriented and holistic), the Accomplisher (executive and field oriented) and the Planner (managerial and task oriented)
                           <br/><br/>-Four experts; the Theorist, the Foreseeing, the Self-reflective and the Factual.
                        </p>
                </div>
                <div className={classes.right}>
                        <h3>It also tells about</h3>
                        <p>-Intrinsic aptitudes in decision making and problem solving; the Deductive, the Inductive, the Affective and the Systematic
                        <br/><br/> - Intrinsic aptitudes in perception and encoding; the Phonetic, the Imaginative, the Experiential and the Procedural
                        </p>
                </div>
                </div>
            </div>
            <div className={classes.title2}>
                <h1> Identities </h1>
                <div className={classes.cardlist}>
                    <li>
                        <Card img={imga} title={"Accomplisher"}> </Card>
                    </li>
                    <li>
                        <Card img={imgb} title={"Demonstrative Mind"}> </Card>
                    </li>
                    <li>
                        <Card img={imgc} title={"Factual"}> </Card>
                    </li>
                    <li>
                        <Card img={imgd} title={"Foreseeing"}> </Card>
                    </li>
                    <li>
                        <Card img={imge} title={"Theorist"}> </Card>
                    </li>
                    <li>
                        <Card img={imgf} title={"Self reflective"}> </Card>
                    </li>
                    <li>
                        <Card img={imgg} title={"Planner"}> </Card>
                    </li>
                    <li>
                        <Card img={imgh} title={"Motivator"}> </Card>
                    </li>

                </div>
            </div>
        </div>
    );
}

export default AboutISI