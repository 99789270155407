import React from 'react'
import classes from "./style/progressbar.module.css"
import ProgressBar from "@ramonak/react-progress-bar";

const ProgresBar = (props) => {
  return (
    <div className={classes.pbar}>
        <h5> {props.title} </h5>
    <ProgressBar 
        completed={props.percent}
        bgColor={props.color}
        baseBgColor="#FFFFFF"
        className={classes.bar}
        height={10}
        labelAlignment="outside"
        labelColor="#000000"
        labelSize={14}
    />
    </div>
  )
}

export default ProgresBar