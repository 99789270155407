import React from 'react'
import classes from "./style/workexperience.module.css"
import CircleIcon from '@mui/icons-material/Circle';
import { PropaneSharp } from '@mui/icons-material';

const Workexperience = (props) => {

function formatDate(date)
{
    return(date?date.slice(0,10):null)
}

  return (
    <div className={classes.workexp}>
    <div className={classes.maintitle}>
        <div className={classes.title}>
            <div style={{color:"#17289C"}}>
               <CircleIcon /> 
            </div>
            <div className={classes.jobtitle}>
                <b>Job title:</b> {props.company}
            </div>
        </div>
        <div className={classes.datee}>
            <b>From</b> {formatDate(props.startDate)} <b>to</b> {formatDate(props.endDate)}
        </div>
    </div>
    <div className={classes.role}>
        {props.role}
    </div>
    <div className={classes.role}>
        {props.hiringbody}
    </div>
    <div className={classes.role}>
        {props.description}
    </div>
    </div>
  )
}

export default Workexperience