import classes from "./style/instructions.module.css"
import React from 'react' 
import { useState } from "react"
import Qimg1 from "../Img/Q_img1.png"
import Qimg2 from "../Img/Q_img2.png"
import bulb from "../Img/bulb.png"

const Instructions = (props) => {
  return (
   <div className={classes.object}>
        <div className={classes.heading}>
            <h1> Instructions! </h1>
            <h3>Answer the following few questions it takes less than 10min <br/> Choose the right score accornding to the following scale </h3>
        </div>

        <ul className={classes.mcq}>
            <li>
            <a>1</a>
            <label>Strongly Disagree</label>
            </li>
            <li>
            <a>2</a>
            <label>Disagree</label>
            </li>
            <li>
            <a>3</a>
            <label>Not Sure</label>
            </li>
            <li>
            <a>4</a>
            <label>Agree</label>
            </li>
            <li>
            <a>5</a>
            <label>Strongly Agree </label>
            </li>
        </ul>

        <div className={classes.guidance}>
            <div className={classes.illustration}><img src={Qimg1}></img></div>
            <div className={classes.guidanceinfo}>
                <div className={classes.title}>
                    <div className={classes.adjustimg}><img src={bulb} /></div>
                    <h2>For Guidance:</h2>
                </div>
                <div className={classes.info}>
                    <ul>
                        <li>
                            <div className={classes.bullet}><p>1</p></div>
                            <p className={classes.infop}>If it is very far from you</p>
                        </li>
                        <li>
                            <div className={classes.bullet}><p>2</p></div>
                            <p className={classes.infop}>If it is not your nature</p>
                        </li>
                        <li>
                            <div className={classes.bullet}><p>3</p></div>
                            <p className={classes.infop}>If you are not sure</p>
                        </li>
                        <li>
                            <div className={classes.bullet}><p>4</p></div>
                            <p className={classes.infop}>If it describes you to some extent </p>
                        </li>
                        <li>
                            <div className={classes.bullet}><p>5</p></div>
                            <p className={classes.infop}>If it describes you perfectly</p>
                        </li>
                    </ul>
                </div>
            </div>
            
        </div>

        <div className={classes.guidance}>
        <div className={classes.guidanceinfox}>
                <div className={classes.title}>
                    <div className={classes.adjustimg}><img src={bulb} /></div>
                    <h2>Tips:</h2>
                </div>
                <div className={classes.info}>
                    <ul>
                        <li>
                            <div className={classes.bulletX}></div>
                            <p className={classes.infop2}> Note that there is no right and wrong answers</p>
                        </li>
                        <li>
                            <div className={classes.bulletX}></div>
                            <p className={classes.infop2}>Be yourself and be honest about the way you normally behave</p>
                        </li>
                        <li>
                            <div className={classes.bulletX}></div>
                            <p className={classes.infop2}>Try not to think too much before giving your answer</p>
                        </li>
                        <li>
                            <div className={classes.bulletX}></div>
                            <p className={classes.infop2}>Answer according to what directly comes to your mind.</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div className={classes.illustrationx}><img src={Qimg2}></img></div>
        </div>
        <div className={classes.f}><a href="#top" className={classes.finish} sx= {{ fontSize:'60px'}} onClick={props.click}> <p> Start </p> </a></div>
   </div>
  )
}

export default Instructions