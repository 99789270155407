import React, { useState, useEffect } from "react";
import classes from "./style/usercard.module.css";
import avatar from "./dymmyData";

const CustomUserCard = (props) => {
  const [iRes1, setiRes1] = useState(parseInt(props.iRes1));
  const [iRes2, setiRes2] = useState(parseInt(props.iRes2));

  return (
    <div className={classes.body}>
      <div>
        <div className={classes.customCard}>
          <div>
            {props.email}
          </div>
          {iRes2 ? (
            <div className={classes.personalities}>
              <div
                className={classes.avatar}
                style={{ backgroundColor: avatar[iRes1].color }}
              >
                {avatar[iRes1].name}
              </div>
              <div
                className={classes.avatar}
                style={{ backgroundColor: avatar[iRes2].color }}
              >
                {avatar[iRes2].name}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default CustomUserCard;
