import React from 'react'
import classes from "./style/pop.module.css"
import { useState } from 'react'
import axios from 'axios'
import Loader from './UI/Loader';

const Addpic = (props) => {
const [ fieldValue,setFieldValue]= useState([])
const [Spinner,setSpinner] = useState(false)

const uploadPic=()=>{
    setSpinner(true)
    const formData = new FormData();
    formData.append("pic",fieldValue)
    
    
    axios.patch("https://matchmaker-77lp.onrender.com/api/"+ props.type +"/uploadImg?pic" , formData , 
    { headers: {Authorization: `Bearer ${sessionStorage.getItem("tokenValue")}`}}).then((res)=>{
        console.log("done")
        setSpinner(false)
        window.location.reload(true)
    }).catch((err)=>{
        setSpinner(false)
        console.log(err)
    })
    
}

  return (
    <div className= {classes.backDrop}>
        <div  className={classes.pop}>
            <div className={classes.addpic}>
                <input id="file" name="imge" type="file" onChange={(event) => {
                            setFieldValue(event.currentTarget.files[0]);
                            }} />
                <div className={classes.addpicbuttons}>
                    <button onClick={uploadPic}>Upload</button>
                    <button className={classes.cancel} onClick={props.closePop}>Cancel</button>
                </div>
            </div>
            {Spinner?
                <>
                <div className={classes.loader}> <Loader/> </div>
                <h4> Please wait, Large file photo size will take more time uploading</h4>
                </>:null}
        </div>
        
    </div>
  )
}

export default Addpic