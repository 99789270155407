import React from "react";
import { useState, useEffect } from "react";
import classes from "./style/profile.module.css";
import EmptyProfile from "../components/EmptyProfile";
import axios from "axios";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Switch, Route } from "react-router-dom";
import CompanySideBar from "../components/CompanySideBar";
import CompanyFullProfile from "../components/CompanyFullProfile";
import Findaboss from "../components/Findaboss";
import Jobmatch from "../components/Jobmatch";
import Filter from "../components/OneTimeLinkUsers";
import Spinner from "../components/UI/Spinner";
import CustomProfile from "../components/CustomProfile";

const CompanyCustomProfile = () => {
  const history = useHistory();

  const Data = useSelector((state) => state);

  // this to redirect not authorized people
  useEffect(() => {
    if (!Data.login) {
      history.push("/");
    }
  }, [Data]);
  let token = sessionStorage.getItem("tokenValue");

  //   async function GetCompanyProfile() {
  //     var config = {
  //         method: 'get',
  //         headers: {Authorization:"Bearer "+token }
  //       };
  //     let response = '';
  //   try {
  //     response = await axios.get('https://matchmaker-77lp.onrender.com/api/company/'+sessionStorage.getItem("ID"),config).then((res) => res.data);

  //     setProfileInfo(response);
  //     setcompletedprofile(response.dataComplete);
  //     setcompletedavatar(response.outvalue);
  //     setSpinner(false)

  //     return (response);
  //   } catch (error) {
  //     setSpinner(false)
  //     if (error.response) {
  //       return (error.response);
  //     }
  //   }
  //   return (response);
  // }

  const Bossquestion = [
    { id: 1, question: "Visionary" },
    { id: 2, question: "People oriented" },
    { id: 3, question: "Artistic" },
    { id: 4, question: "Imaginative and innovative" },
    { id: 5, question: "Hunts opportunities" },
    { id: 6, question: "Good as a doer" },
    { id: 7, question: "Provide structure in the organization" },
    { id: 8, question: "Define roles and pathways" },
    { id: 9, question: "Concrete and materialistic" },
    { id: 10, question: "Planting organizational spirit" },
    { id: 11, question: "Strict and obedient" },
    { id: 12, question: "Broad minded" },
    { id: 13, question: "Goal oriented" },
    { id: 14, question: "Play with ideas and theories" },
    { id: 15, question: "Interested in the field work" },
    { id: 16, question: "Interpersonal" },
    { id: 17, question: "Strategic and holistic" },
    { id: 18, question: "Abstract and conceptual" },
    { id: 19, question: "Practical and interactive" },
    { id: 20, question: "Perfectionist" },
    { id: 21, question: "Man of action" },
    { id: 22, question: "Planner" },
    { id: 23, question: "Evaluator" },
    { id: 24, question: "  Allocate resources" },
    { id: 25, question: "Create and follow up the process" },
    { id: 26, question: "Systematic and organized" },
    { id: 27, question: "Reserved and traditional" },
    { id: 28, question: "Good at critical thinking" },
  ];

  const Jobquestion = [
    { id: 1, question: "Focus on patterns and meanings" },
    { id: 2, question: "Logic " },
    { id: 3, question: "Sympathize with the problem" },
    { id: 4, question: "Goal‐ and task‐ oriented" },
    { id: 5, question: "Analytical and compare between alternatives" },
    { id: 6, question: "Can deal with abstract insights" },
    { id: 7, question: "Avoid situations that may lead to conflict" },
    {
      id: 8,
      question: "Prefer to take in information through the five senses",
    },
    { id: 9, question: "Clever at cause‐and‐effect reasoning" },
    { id: 10, question: "Value ingenuity" },
    { id: 11, question: "Observe connections and relationships" },
    { id: 12, question: "Value the climate of affirmation and acceptance" },
    { id: 13, question: "More confident in tangible results" },
    { id: 14, question: "Prefer established ways of doing things" },
    { id: 15, question: "Respect personal appreciation" },
    { id: 16, question: "Good at critiquing" },
    { id: 17, question: "Conceptual and theoretical" },
    { id: 18, question: "Tactful" },
    { id: 19, question: "Prefer to describe things literally" },
    { id: 20, question: "Attached to reality" },
    { id: 21, question: "Clever at memorizing facts" },
    { id: 22, question: "Build on personal experience" },
    { id: 23, question: "Innovative" },
    { id: 24, question: "Visionary" },
    { id: 25, question: "Direct and clear in communication" },
  ];

  return (
    <div className={classes.Profile}>
      <CompanySideBar />
      <Switch>
        <Route path="/CompanyView/Profile">{<CustomProfile />}</Route>
        <Route path="/CompanyView/Users">{/* Company filter*/ <Filter />}</Route>
        <Route path="/CompanyView/findboss">
          {/* Company find a boss*/}
          <Findaboss questions={Bossquestion} />
        </Route>
        <Route path="/CompanyView/jobmatch">
          {/* Company job match*/}
          <Jobmatch questions={Jobquestion} />
        </Route>
        <Route path="/CompanyView/bill">
          <EmptyProfile prof={2} />
        </Route>
      </Switch>
    </div>
  );
};

export default CompanyCustomProfile;
