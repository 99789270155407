import React from 'react'
import classes from './style/emptyprofile.module.css'
import ErrorIcon from '@mui/icons-material/Error';
import nodata from '../assets/no_results.png';
import nobilling from '../assets/nobilling.png';
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { useState} from "react";
import { Link } from "react-router-dom";
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';

const styleStats = {
  position: "relative",
  top: "5%",
  left: "48%",
  transform: "translate(-50%, -50%)",
  width: "700px",
  bgcolor: "white",
  border: "0px",
  boxShadow: 24,
  p: 2,
  borderRadius: "50px",

};
const InfoToolTip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgb(69, 83, 175)',
    maxWidth: 620,
    fontSize: theme.typography.pxToRem(18),
    border: '1px solid #dadde9',
  },
}));

const EmptyProfile = (props) => {
  
  const [openModal, setOpenModal] = useState(false);

  function handleBagdeHover() 
  {
    setOpenModal(true)
  }

  function handleBagdeOut() 
  {
    setOpenModal(false)
  }
  return (
    <div className={classes.emptyprofile}>
    {}
    <div style={{ width: "fit-content", height: "fit-content" }}>
        <div  >
          <InfoToolTip
            placement={"right"}
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
            title={
              <React.Fragment>
                <Typography fontSize={18} color="inherit">
                  Note That You Must Complete Your Profile So Companies Can Find You
                </Typography>
              </React.Fragment>
            }
          >
            <ErrorIcon className={classes.badge} style={{ fontSize: 60 }} />
          </InfoToolTip>
        </div>
      </div>
    <div  className={classes.nodatapic}>
    {props.prof===2?<img src={nobilling} className={classes.nobilling}/> :<img src={nodata}/>}
    </div>
    {props.prof===2?<div className={classes.noinfo}>No Rush!<h5>You are on a free trial for now</h5></div>:<div className={classes.noinfo}>No Data Found Yet</div>}
    {props.prof===0?<Link to="/Userform" ><div className={classes.setupprofile}>Set Up My Profile</div></Link>:null}
    {props.prof===3?<Link to="/Companyform" ><div className={classes.setupprofile}>Set Up Company Profile</div></Link>:null}
    {props.prof===1?<Link to="/q" ><div className={classes.setupprofile}>To test</div></Link>:null}
    </div>
  )
}

export default EmptyProfile