import classes from "./style/Isi.module.css"
import React from 'react'
import isi1 from "../assets/MainLogo/Isi2.jpg"
import isi2 from "../assets/MainLogo/Isi1.jpg" 
import { useState } from "react"

const Isi = () => {
    const[is,setis]=useState(false);

    function clickhandler () {
        if (is)
        {
            setis(false);
        }
        else
        {
            setis(true);
        }
        
    }

  return (
   <div className={classes.isi}>
    <h1> Our Technology </h1>
     <div className={classes.infContainer}>
        <div className={classes.txtContainer}>
            {is ? <img src={isi2} onClick={clickhandler} /> : <img src={isi1} onClick={clickhandler}/> }
        </div>
        <div className={classes.txtContainer}>
            <h4>While traditional hiring methods and personality tests often struggle to pinpoint the key to successful recruitment, Matchmaker offers a more effective solution. Our unique technology, the Intellectual Style Inventory (ISI), goes beyond basic personality traits to assess a candidate's intellectual strengths and how they align with specific job requirements. This data-driven approach helps businesses make confident hiring decisions by ensuring a strong cultural fit and maximizing the potential for top performance.</h4>
        </div>
      </div>
   </div>
  )
}

export default Isi