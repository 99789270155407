import {React,useState,useEffect} from 'react'
import classes from './style/mcqchoices.module.css'

const McqChoices = (props)=>{
    const [active1, setactive1] = useState(0);
    const [active2, setactive2] = useState(0);
    const [active3, setactive3] = useState(0);
    const [active4, setactive4] = useState(0);
    const [active5, setactive5] = useState(0);

    useEffect(() => {
        if (props.array[props.index]===1) 
        {
            setactive1(1);
        }
        else if (props.array[props.index]===2) 
        {
            setactive2(1);
        }
        else if (props.array[props.index]===3) 
        {
            setactive3(1);
        }
        else if (props.array[props.index]===4) 
        {
            setactive4(1);
        }
        else if (props.array[props.index]===5) 
        {
            setactive5(1);
        }
      });

    function resetall() {
        setactive1(0);
        setactive2(0);
        setactive3(0);
        setactive4(0);
        setactive5(0);
    };

    function adjust(X){
        resetall();
        if (X===1){setactive1(1);}
        else if (X===2){setactive2(1);}
        else if (X===3){setactive3(1);}
        else if (X===4){setactive4(1);}
        else if (X===5){setactive5(1);}

        props.array[props.index]=X;
    }

    return(
        <ul className={classes.mcq}>
            <li className={active1?classes.active: null} onClick={() => adjust(1)}>
            <a>1</a>
            <label>Strongly Disagree</label>
            </li>
            <li className={active2?classes.active: null} onClick={() => adjust(2)}>
            <a>2</a>
            <label>Disagree</label>
            </li>
            <li className={active3?classes.active: null} onClick={() => adjust(3)}>
            <a>3</a>
            <label>Not Sure</label>
            </li>
            <li className={active4?classes.active: null} onClick={() => adjust(4)}>
            <a>4</a>
            <label>Agree</label>
            </li>
            <li className={active5?classes.active: null} onClick={() => adjust(5)}>
            <a>5</a>
            <label>Strongly Agree </label>
            </li>
        </ul>
    );
}

export default McqChoices