import React from 'react';
import classes from './style/avatarprofile.module.css'
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';
import ErrorIcon from '@mui/icons-material/Error';
import ProgresBar from './Progressbar';

const AvatarProfile = (props) => {
  const InfoToolTip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'rgb(69, 83, 175)',
      maxWidth: 620,
      fontSize: theme.typography.pxToRem(18),
      border: '1px solid #dadde9',
    },
  }));
  
  
  return (
    <div className={classes.fullprofile}>
      <div style={{ width: "fit-content", height: "fit-content" }}>
        <div>
          {props.view?null:<InfoToolTip
            placement={"right"}
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
            title={
              <React.Fragment>
                <Typography fontSize={18} color="inherit">
                  Note That You Must Complete Your Profile So Companies Can Find You
                </Typography>
              </React.Fragment>
            }
          >
            <ErrorIcon className={classes.badge} style={{ fontSize: 60 }} />
          </InfoToolTip>}
          
        </div>
      </div>   
      <div className={classes.maininfo}>
        <div className={classes.userinfo}>
            <div className={classes.imgholder}>
                <img src={props.avatar} alt="Avatar image"/>
            </div>
            <div className={classes.avatarnaming}>
              <h2> {props.avatarname}</h2>
              <h4 style={{fontSize:"17px"}}>Personality type</h4>
            </div>
        </div>
        <div className={classes.des}>
            <h3> Who is a {props.avatarname} ?</h3>
            <p> {props.des}</p>
        </div>

        <div className={classes.userinfo}>
            <div className={classes.imgholder}>
                <img src={props.avatar2} alt="Avatar image"/>
            </div>
            <div className={classes.avatarnaming}>
            <h2> {props.avatarname2}</h2>
            <h4 style={{fontSize:"17px"}}>Leader-ship style</h4>
            </div>
        </div>
        <div className={classes.des}>
            <h3> Who is a {props.avatarname2} ?</h3>
            <p> {props.des2}</p>
        </div>
        

        <div className={classes.perc}>
            <div className={classes.percentages}>
                <h4> Thinking Styles </h4>
                <ProgresBar percent={parseInt(props.flt)} color={"#3B55FF"} title={"Deductive"}/>
                <ProgresBar percent={parseInt(props.frt)} color={"#3B55FF"} title={"Inductive"}/>
                <ProgresBar percent={parseInt(props.brt)} color={"#3B55FF"} title={"Affective"}/>
                <ProgresBar percent={parseInt(props.blt)} color={"#3B55FF"} title={"Systematic"}/>
            </div>
            <div className={classes.percentages}>
                <h4> Perceptional Styles </h4>
                <ProgresBar percent={parseInt(props.flp)} color={"#F7A131"} title={"Phonetic"}/>
                <ProgresBar percent={parseInt(props.frp)} color={"#F7A131"} title={"Imaginative"}/>
                <ProgresBar percent={parseInt(props.brp)} color={"#F7A131"} title={"Experiential"}/>
                <ProgresBar percent={parseInt(props.blp)} color={"#F7A131"} title={"Procedural"}/>
            </div>
        </div>
      </div>
    </div>
  )
}

export default AvatarProfile