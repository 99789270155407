import React from 'react'
import classes from "./style/filter.module.css"
import {Formik, Field, Form} from 'formik'

const avatars1 = [
    'Theorist','Foreseeing', 'Self Reflective', 'Factual'
]

const avatars2 = [
    'Demonstarive Mind', 'Motivator', 'Accomplisher', 'Planner'
]

const HLedu = ["Undergraduate student", "Non-faculty graduate", "Faculty degree", "Post graduate degree"];
const fac = ["Engineering", "Medicine", "Pharmacy", "Veterinary Medicine", "Dentistry", "Commerce", "Arts", "Education",  "Business administration", "Law", "Science", "Nursing", "Physical Education", "Computers and Information", "Economics and Political Sciences", "Media", "Physical Therapy", "Agriculture", "Applied Health Sciences Technology", "Kindergarten", "Applied Arts", "Al-Alsun", "Social Work", "Earth Sciences", "Tourism and Hotels", "Special Needs Science", "Navigation Sciences and Space Technology", "Other"];
const jobp = ["Architecture and Construction", "Agriculture", "Education and Training", "Design", "Manufacturing", "Health Services", "Medicine", "Fitness and Beauty", "Banking", "Trading", "Fund Raising", "law", "Advertisement and Promotion", "Marketing", "International affairs", "Business Management", "Real Estate", "Data Management", "Mass Communication", "Pharmacy and Drug Industry", "Tourism", "Arts and Music", "Media", "Press", "Transportation", "Academia", "Research", "Innovation and Technology Transfer", "Entrepreneurship", "Digital Services", "Food industry", "Human Resources", "Fashion", "Other"];
const yexp = ["<2 years", "2-5 years", ">5 years", ">10 years"];

const FilterDrop = (props) => {
    
    function dropDownMenu(option,index) {
        return (
            <option  key = {index}className={classes.option} value={option}>{option}</option>
        )
    }

    function dropDownMenuAvatar(option,index) {
        return (
            <option  key = {index}className={classes.option} value={index}>{option}</option>
        )
    }

    const onformSubmit = (data)=>{
        var search='';
        
        if (data.jobPosition){
            search=search+"&jobPosition="+data.jobPosition;
        }
        
        if (data.yearsOfExp){
            search=search+"&yearsOfExp="+data.yearsOfExp;
        }

        if (data.HLOfEdu){
            search=search+"&HLOfEdu="+data.HLOfEdu;
        }

        if (data.faculty){
            search=search+"&faculty="+data.faculty;
        }

        if (data.iRes1){
            search=search+"&iRes1="+data.iRes1;
        }

        if (data.iRes2){
            search=search+"&iRes2="+data.iRes2;
        }

        props.search(search)
    }

    const handleReset = (data) => {
        onformSubmit(data)
    };
    
    const initialValues = {
        jobPosition:"",
        yearsOfExp:"",
        HLOfEdu:"",
        faculty:"",
        iRes1:"",
        iRes2:"",
    }

  return (
    <div className={classes.filterdrop}>
        <h1>Filter through users</h1>
        <Formik initialValues={initialValues} onSubmit={values=>onformSubmit(values)} onReset={values=>handleReset(values)}>
            <Form>
                <div className={classes.box}>
                    <div className={classes.inputbox}>
                        <label className={classes.label}>Job Position</label>
                        <Field as="select" className={classes.input} type="text" name="jobPosition" placeholder="Type here">
                            <option value="" disabled="disabled"> Select from list ...</option>
                            {jobp.map((g, index)=> dropDownMenu(g,index))} 
                        </Field>
                    </div>
                    <div className={classes.inputbox}>
                        <label className={classes.label}>Years of Experience</label>
                        <Field as="select" className={classes.input} type="text" name="yearsOfExp" placeholder="Type here">
                            <option value="" disabled="disabled"> Select from list ...</option>
                            {yexp.map((g, index)=> dropDownMenu(g,index))} 
                        </Field>
                    </div>
                    <div className={classes.inputbox}>
                        <label className={classes.label}>Highest Level of Education</label>
                        <Field as="select" className={classes.input} type="text" name="HLOfEdu" placeholder="Type here">
                            <option value="" disabled="disabled"> Select from list ...</option>
                            {HLedu.map((g, index)=> dropDownMenu(g,index))} 
                        </Field>
                    </div>
                    <div className={classes.inputbox}>
                        <label className={classes.label}>Faculty</label>
                        <Field as="select" className={classes.input} type="text" name="faculty" placeholder="Type here">
                            <option value="" disabled="disabled"> Select from list ...</option>
                            {fac.map((g, index)=> dropDownMenu(g,index))} 
                        </Field>
                    </div>
                    <div className={classes.inputbox}>
                        <label className={classes.label}>Personality type</label>
                        <Field as="select" className={classes.input} type="text" name="iRes1" placeholder="Type here">
                            <option value="" disabled="disabled"> Select the avatar</option>
                            {avatars1.map((g, index)=> dropDownMenuAvatar(g,index))} 
                        </Field>
                    </div>
                    <div className={classes.inputbox}>
                        <label className={classes.label}>Leader-ship style</label>
                        <Field as="select" className={classes.input} type="text" name="iRes2" placeholder="Type here">
                            <option value="" disabled="disabled"> Select the avatar</option>
                            {avatars2.map((g, index)=> dropDownMenuAvatar(g,index+4))} 
                        </Field>
                    </div>
                </div>
                <div className={classes.f}>
                    <button type="submit">
                        Search
                    </button>
                    <button style={{backgroundColor:"#525b61", border:"none"}} type="reset">
                        Reset
                    </button>
                </div>
            </Form>
        </Formik>
    </div>
  )
}

export default FilterDrop