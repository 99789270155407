import React from 'react'
import classes from "./style/pop.module.css"
const Pop = ({type,closePop}) => {
  return (
    <div className= {classes.backDrop}>
        <div className={classes.pop}>
            {type==="verify" ? <h2>An email has been sent, to you go to verify your account!!</h2>: type==="err" ? 
            <h2>Opps Somthing went wrong</h2>
            :
            <h2>Email is not verified, open your email  to complete verification step</h2>
            } 
            {
            type === "verify" ? 
            <button onClick={()=>closePop()}>OK</button>
            : type==="err" ? 
            <button onClick={()=>closePop()}>Refresh</button>: 
            <button onClick={()=>closePop()}>OK</button>
            }
        </div>

    </div>
  )
}

export default Pop