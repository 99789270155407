import React from "react";
import classes from "./style/filter.module.css";
import FilterDrop from "./FilterDropDown";
import { useState, useEffect } from "react";
import axios from "axios";
import CustomUserCard from "./CustomUserCard";
import Loader from "./UI/Loader";
import ViewPersonality from "./ViewPersonality";
import CloseIcon from '@mui/icons-material/Close';

const OneTimeLinkUsers = (props) => {
  const [users, setUsers] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [viewProfile, setViewProfile] = useState(false);
  const [viewUser, setViewUser] = useState({});

  async function getUsersOneTimeLink(e) {
    let token = sessionStorage.getItem("tokenValue");
    let response = "";
    try {
      response = await axios({
        method: "get",
        url: "https://matchmaker-77lp.onrender.com/api/company/allOneTimeUsers",
        headers: { Authorization: "Bearer " + token },
      });
      console.log(response.data.oneTimeUsers);
      setUsers(response.data.oneTimeUsers);
    } catch (error) {}
  }

  useEffect(() => {
    getUsersOneTimeLink();
  }, []);

  const handleUserClick = (user) => () => {
    if (user.isFilled) {
      setViewProfile(true);
      setViewUser(user);
    }
  };

  return (
    <div className={classes.filter}>
      <div>
        {viewProfile ? (
          <div className={classes.viewProfile}>
            <div className={classes.viewPersonalityHeader}>
              <h1>User Email: {viewUser.email}</h1>
              <button
                onClick={() => {
                  setViewProfile(false);
                }}
                className={classes.controlBtn}
              >
               < CloseIcon fontSize="large" />
              </button>
            </div>
            <div className={classes.viewProfile}>
              <ViewPersonality
                iRes1={viewUser.iRes1}
                iRes2={viewUser.iRes2}
                flt={viewUser.flt}
                frt={viewUser.frt}
                brt={viewUser.brt}
                blt={viewUser.blt}
                flp={viewUser.flp}
                frp={viewUser.frp}
                brp={viewUser.brp}
                blp={viewUser.blp}
              />
            </div>
          </div>
        ) : null}
      </div>
      {users.length !== 0 && !spinner ? (
        <div className={classes.filterHeader}>
          <h1>All Users</h1>
        </div>
      ) : null}
      {users.length !== 0 && !spinner ? (
        users.map((user) => {
          return (
            <div onClick={handleUserClick(user)}>
              <CustomUserCard
                _id={user._id}
                email={user.email}
                iRes1={user.iRes1}
                iRes2={user.iRes2}
              />
            </div>
          );
        })
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default OneTimeLinkUsers;
