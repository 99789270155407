import classes from './style/fullprofile.module.css'
import { useState ,useEffect} from "react";
import EditIcon from '@mui/icons-material/Edit';
import Fade from '@mui/material/Fade';
import Spinner from './UI/Spinner';
import Addpic from './Addpic';
import { NavLink } from 'react-router-dom';



const CompanyFullProfile = (props) => {
  const [addPic,setAddPic]=useState(false)
  const[spinner,setSpinner] = useState(true)
    function delay(time) {
        return new Promise(resolve => setTimeout(resolve, time));
      }
    
      async function fix() {
        await delay(1000);
        setSpinner(false)
        
      }
      useEffect(()=>{
       
        fix()
     
    })
    
  const closePop = ()=>{
    setAddPic(false)
  }

    
  return (
    <>
    {spinner? <Spinner/>
    
    :
    <div className={classes.fullprofile}>
      {
        addPic && 
        <Addpic type={"company"} closePop={closePop}/>
      }
       <div className={classes.maininfo}>
        <div className={classes.userinfoContainer}>
          <div className={classes.userInfo}>
            <div className={classes.profimage}>
              <div className={classes.profileImage}>
                <img src={props.pic} alt="Profile Pic"/>
              </div>
              <div className={classes.fieldContainer}>
                    <button onClick={()=>setAddPic(true)}><p>+</p></button>
              </div>
          </div>
            <div className={classes.info}>
              <div className={classes.username}>{props.companyName}</div>
              <div className={classes.location}>{props.companyAddress}</div>
            </div>
          </div>
          <div >
            <NavLink to='/EditCompanyInfo'>
              <EditIcon  style={{ fontSize: 25 }}/>
            </NavLink>
          </div>
        </div>
        <div className={classes.lowersectioninfo}>
          <div className={classes.pinfo}>
            <div className={classes.PersonalInfo}>
              <div className={classes.bdate}>Industry Type
                <input value={props.industry} readonly />
              </div>
              <div className={classes.bdate}>Phone Number
                <input value={props.companyPhone} readonly />
              </div>
            </div>  
          </div>
        </div>
      </div>
      <div className={classes.maininfo}>
        <div className={classes.education}>About Company</div>
        <div className={classes.mainaboutcompany}></div>
        <div className={classes.aboutcompany}>
            <div className={classes.abtcompsubtitle}><p>Legal Entity: </p> <h3>{props.legalEntity}</h3></div>
            <div className={classes.abtcompsubtitle}><p>Company Size:</p><h3>{props.companySize}</h3></div>
            <div className={classes.abtcompsubtitle}><p>Commercial Registration Number: </p><h3>{props.commercialRegistrationNumber}</h3></div>
            <div className={classes.abtcompsubtitle}><p>Registration Year:</p><h3>{props.registrationYear}</h3></div>
            <div className={classes.abtcompsubtitle}>
              <p>Website: <a href={props.URL} target='_blank'> Official website link</a></p>
            </div>
        </div>
        <div className={classes.aboutcompany}></div>
      </div>


      <div className={classes.maininfo}>
        <div className={classes.education}>Social Accounts</div>

        <div className={classes.aboutcompany}>
            <div className={classes.abtcompsubtitle}>
              <p>Facebook: <a href={props.facebook} target='_blank'>facebook link  </a></p>
            </div>
            <div className={classes.abtcompsubtitle}>
              <p>LinkedIn: <a href={props.linkedin} target='_blank'> linkedin link</a></p>
            </div>
            <div className={classes.abtcompsubtitle}>
              <p>Other: <a href={props.instagram} target='_blank'> Other Social Account link</a></p>
            </div>
        </div>
      </div>
      

      <div className={classes.maininfo}>
        <div className={classes.education}>Contact</div>

        <div className={classes.aboutcompany}>
            <div className={classes.abtcompsubtitle}><p>Full Name: {props.CPD_FullName}</p> </div>
            <div className={classes.abtcompsubtitle}><p>ID number: {props.CPD_IDNumber}</p></div>
            <div className={classes.abtcompsubtitle}><p>Position: {props.CPD_Pos}</p></div>
            <div className={classes.abtcompsubtitle}><p>Mobile Number: {props.CPD_mobileNumber}</p></div>
            <div className={classes.abtcompsubtitle}><p>Email: {props.CPD_emailAddress}</p></div>
        </div>
      </div>

      </div>
  }
    </>
  )
}

export default CompanyFullProfile