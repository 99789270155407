import React , { useState ,useEffect} from 'react'
import classes from './style/success.module.css'
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CheckIcon from '@mui/icons-material/Check';
import { Link } from "react-router-dom";

const SuccessModal = (props) => {
    const styleStats = {
        position: "absolute",
        top: "50%",
        left: "50%",
        width:"50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "white",
        border: "0px",
        boxShadow: 24,
        borderRadius: "50px",
      };

const [openModal, setOpenModal] = useState(true);
    return (
        <div>
            <Modal open={openModal} >
                <Box sx={styleStats}>
                    <div className={classes.congrats}>
                    <div className={classes.modalhead}></div>
                    <div className={classes.congratsbody}>
                        <div className={classes.congratsbody1}></div>
                        <div className={classes.congratsbody2}>
                        <div className={classes.headicon}><div className={classes.checklogo} > <CheckIcon className={classes.check} style={{ fontSize: 100}}/></div></div>
                        <div className={classes.congratstext}>Congratulations!</div>
                        </div>
                    </div>
                    <div className={classes.congratsdone}>You've completed your {props.msg}, Tab to See Results</div>
                        <Link to={props.link} className={classes.Link}><div className={classes.setupprofile}>See Results</div></Link>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}
export default SuccessModal