import React, { useEffect } from "react";
import classes from "./style/customProfile.module.css";
import { useHistory } from "react-router-dom";
import { Formik, Field, ErrorMessage, Form, FieldArray } from "formik";
import { useState } from "react";
import Results from "./Results";
import "animate.css";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EmailIcon from "@mui/icons-material/Email";

const CustomProfile = (props) => {
  const [urlLink, setUrlLink] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (!sessionStorage.getItem("tokenValue")) {
      window.location.href = "/";
    }
  }, []);

  const handleCopy = () => {
    navigator.clipboard.writeText(urlLink);
    toast.success("Link Copied to Clipboard");
  };

  function extractOneTimeLink(url) {
    // Use a regular expression to match the desired pattern
    const regex = /oneTimeLink\/([a-zA-Z0-9]+)/;
    const match = url.match(regex);

    // Check if there's a match
    if (match && match.length > 1) {
      return match[1]; // Return the captured group (oneTimeLink value)
    } else {
      return null; // Return null if no match found
    }
  }

  async function callMyFunction(e) {
    // Prevent the browser from reloading the page
    e.preventDefault();

    // Read the form data
    const email = e.target[0].value;
    setEmail(email);
    let token = sessionStorage.getItem("tokenValue");

    let response = "";
    try {
      response = await axios({
        method: "post",
        url: "https://matchmaker-77lp.onrender.com/api/company/generateLink",
        data: { email: email },
        headers: { Authorization: "Bearer " + token },
      });

      const extractedLink = extractOneTimeLink(response.data.url);
      setUrlLink(
        "https://match-maker.vercel.app/identification/" + extractedLink
      );
    } catch (err) {
      return Promise.reject(err);
    }
  }

  const handleSubmit = (e) => {
    toast.promise(callMyFunction(e), {
      loading: "Loading...",
      success: "Link generated successfully ",
      error: (err) => {
        // Handle error specifically
        return "This Email was already requested!"; // Return the error message
      },
    });
  };
  return (
    <div className={classes.main}>
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{
          className: "",
          style: {
            padding: "16px",
            fontSize: "1.8rem",
          },
        }}
      />
      <h1> Welcome Back !</h1>
      <div className={classes.info}>
        Request a single use link
        <form onSubmit={handleSubmit}>
          <label>
            <p>Add Email</p>
            <input type="email" name="myInput" defaultValue="xyz@gmail.com" />
          </label>

          {urlLink ? (
            <div
              className={classes.resetBtn}
              onClick={() => {
                setUrlLink("");
              }}
            >
              Reset
            </div>
          ) : (
            <button type="submit" >
              Request
            </button>
          )}
        </form>
        {urlLink ? (
          <div className={classes.urlLink}>
            <h1>{urlLink ? urlLink : null}</h1>
            <div className={classes.control}>
              <button className={classes.controlBtn} onClick={handleCopy}>
                <ContentCopyIcon fontSize="large" />
              </button>
              <a
                className={classes.controlBtn}
                href={`mailto:${email}?subject=MatchMaker Survey&body=This email contains the single use link [copy the following link] ${urlLink}`}
              >
                <EmailIcon fontSize="large" />
              </a>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
export default CustomProfile;
