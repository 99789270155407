import React from 'react'
import classes from './style/certificates.module.css'
import CircleIcon from '@mui/icons-material/Circle';

const Certificates = (props) => {
  return (
    <div className={classes.certificates}>
       <div className={classes.title}><div style={{color:"#17289C"}}><CircleIcon /></div>
       <p>{props.name}</p>
       </div>
       <div className={classes.body}>{props.body}</div>
    </div>
  )
}

export default Certificates