import React from "react";
import { useEffect } from "react";
import AvatarProfile from "./AvatarProfile";
import Avatar from "./dymmyData";
import classes from "../pages/style/profile.module.css";

const ViewPersonality = (props) => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    console.log(props);
  }, []);

  return (
    <div className={classes.Profile}>
      <AvatarProfile
        avatar={Avatar[props.iRes1].img}
        avatarname={Avatar[props.iRes1].name}
        des={Avatar[props.iRes1].description}
        avatar2={Avatar[props.iRes2].img}
        avatarname2={Avatar[props.iRes2].name}
        des2={Avatar[props.iRes2].description}
        flt={props.flt}
        frt={props.frt}
        brt={props.brt}
        blt={props.blt}
        flp={props.flp}
        frp={props.frp}
        brp={props.brp}
        blp={props.blp}
        view={1}
      />
    </div>
  );
};

export default ViewPersonality;
