import React from 'react'
import classes from './style/results.module.css'
import ProgresBar from './Progressbar';
import products from './dymmyData';



const Results = (props) => {
  return (
    <div className={classes.results}>   
        <div className={classes.character}>
          <h1 style={{color:products[props.n].color}}>{products[props.n].name}</h1>
          <div className={classes.charbody}>
            <img alt='a' src={products[props.n].chartimg} />
            <div className={classes.chardetails}>
              <p>{products[props.n].result}</p>
              <ProgresBar percent={parseInt(props.f)} color={products[props.n].color} />
            </div>
          </div>
        </div>

        <div className={classes.character}>
          <h1 style={{color:products[props.n+1].color}}>{products[props.n+1].name}</h1>
          <div className={classes.charbody}>
            <img alt='a' src={products[props.n+1].chartimg} />
            <div className={classes.chardetails}>
              <p>{products[props.n+1].result}</p>
              <ProgresBar percent={parseInt(props.s)} color={products[props.n+1].color} />
            </div>
          </div>
        </div>

        <div className={classes.character}>
          <h1 style={{color:products[props.n+2].color}}>{products[props.n+2].name}</h1>
          <div className={classes.charbody}>
            <img alt='a' src={products[props.n+2].chartimg} />
            <div className={classes.chardetails}>
              <p>{products[props.n+2].result}</p>
              <ProgresBar percent={parseInt(props.t)} color={products[props.n+2].color} />
            </div>
          </div>
        </div>

        <div className={classes.character}>
          <h1 style={{color:products[props.n+3].color}}>{products[props.n+3].name}</h1>
          <div className={classes.charbody}>
            <img alt='a' src={products[props.n+3].chartimg} />
            <div className={classes.chardetails}>
              <p>{products[props.n+3].result}</p>
              <ProgresBar percent={parseInt(props.fo)} color={products[props.n+3].color} />
            </div>
          </div>
        </div>
    </div>
  )
}

export default Results