import React, {useState, useEffect} from 'react'
import classes from './style/usercard.module.css'
import avatar from './dymmyData'
import { useHistory } from "react-router-dom";

const UserCard = (props) => {

  const history = useHistory();
  const [iRes1, setiRes1]=useState(parseInt(props.iRes1))
  const [iRes2, setiRes2]=useState(parseInt(props.iRes2))
  function handleUserClick()
    {
      sessionStorage.setItem("viewID",props._id)
      history.push("/viewProfile/"+props._id);
    }
  
  return (
    <div className={classes.body} onClick={handleUserClick}>
      <div>
        <div className={classes.sub1}>
          <div className={classes.name}>{props.name}</div>
          <a href={"mailto:"+props.email} className={classes.job}>{props.email}</a>
        </div>
        <div className={classes.sub1}>
          <div className={classes.job}>Field: {props.jobPosition}</div>
          <div className={classes.job}> Experience: {props.yearsOfExp}</div>
        </div>
        <div className={classes.sub1}>
          <div className={classes.job}>{props.HLOfEdu}</div>
          <div className={classes.job}>Faculty: {props.faculty}</div>
        </div>

        {iRes2?
        <div className={classes.sub2}>
          <div className={classes.avatar} style={{backgroundColor:avatar[iRes1].color}}>
            {avatar[iRes1].name}
          </div>
          <div className={classes.avatar} style={{backgroundColor:avatar[iRes2].color}}>
            {avatar[iRes2].name}
          </div>
        </div>:null}
      </div>
      <div className={classes.btn}>
        <h4 className={classes.hide}>View user</h4>
        <button> {">"} </button>
      </div>
    </div>
  )
}

export default UserCard