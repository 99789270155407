import React from 'react'
import classes from './style/filter.module.css'
import FilterDrop from './FilterDropDown'
import { useState, useEffect } from 'react'
import axios from 'axios';
import UserCard from './UserCard';
import Loader from './UI/Loader';


const Filter = (props) => {
  let token = sessionStorage.getItem("tokenValue");
  const [users, setUsers] = useState([])
  const [Search,setSearch] = useState("");
  const[spinner,setSpinner] = useState(false)

  async function GetCompanyProfile() {
    setSpinner(true)
    var config = {
        method: 'get',
        headers: {Authorization:"Bearer "+token }
      };
    let response = '';
  try {
    response = await axios.get('https://matchmaker-77lp.onrender.com/api/company/search?'+Search,config).then((res) => res.data);

    setSpinner(false)
    return (response);
  } catch (error) {
    if (error.response) {
      setSpinner(false)
      return (error.response);
    }
  }
  setSpinner(false)
  return (response);
}

React.useEffect(() => {
  (async () => {
    const resp = await GetCompanyProfile();
    setUsers(resp.users)
  })();
}, [Search]);

function leaderboardMap(board) {
  return (
    <UserCard
      key={board._id}
      _id={board._id}
      name={board.name}
      email={board.email}
      jobPosition={board.jobPosition}
      yearsOfExp={board.yearsOfExp}
      HLOfEdu={board.HLOfEdu}
      faculty={board.faculty}
      iRes1={board.iRes1}
      iRes2={board.iRes2}
    />
  )
}

  return (
    <div className={classes.filter}>
      <FilterDrop search={setSearch} />
      {users.length!=0&&!spinner?users.map(leaderboardMap):<Loader/>}
    </div>
  )
}

export default Filter