export const SliderData = [
    {
        msg1:  '1. "My beliefs are built on logic"',
        msg2:  '2. "In a conversation I search for hidden meanings and observe people while speaking"',
        msg3: '3. "I search for harmony in everything"',
        msg4: '4. "I always consider the feelings of others when taking decisions"',
        msg5: '5. "I consider practical experimentation the best way for learning"',
        msg6: '6. "My mind is always busy with tasks"',
        msg7: '7. "Bounded shapes attract my attention in a picture"',
        msg8: '8. "People see me clever at accomplishing routine work and solving daily problems"',
    },
    {
        msg1:  '9. "I remember the order of memories precisely"',
        msg2:  '10. "Others always say that I have a philosophy in life"',
        msg3: '11. "I judge with my mind not my feelings"',
        msg4: '12. "People like to share their happiness or sadness with me"',
        msg5: '13. " I prefer to use clear and confirmative words"',
        msg6: '14. "I prefer prominent words which direct my mind"',
        msg7: '15. "I dont pay a lot of attention to drawing distant future plans"',
        msg8: '16. "Peacefulness and harmony increase my productivity at work"',
    },
    {
        msg1:  '17. "Upon using a device for the first time, I prefer more to look at illustrations and drawings"',
        msg2:  '18. "I prefer clear and factual data upon taking decisions"',
        msg3: '19. "I am better at the work which is well defined"',
        msg4: '20. "Upon memorizing I prefer to read to myself in a loud voice"',
        msg5: '21. "I feel too much sympathy with people in trouble"',
        msg6: '22. "I am clever at analysis and choosing between alternatives upon making a decision"',
        msg7: '23. "I am better at the work which deals with diagrams and pictures"',
        msg8: '24. "I always find unusual solutions to problems"',
    },
    {
        msg1:  '25. "To explain something I prefer to draw it for illustration"',
        msg2:  '26. "I cannot concentrate with visual distractions"',
        msg3: '27. "I like novels which describe the seen as if I am watching it"',
        msg4: '28. "Prevalent bad feelings in the working environment distract my concentration"',
        msg5: '29. "Upon using a device for the first time, I prefer more to read the leaflet"',
        msg6: '30. "I am good at reading body language and facial expressions"',
        msg7: '31. "I am ceand factual data upon taking decisions."',
        msg8: '32. "I always have a holistic vision"',
    },
    {
        msg1:  '33. "I take my decisions based on the concrete reality"',
        msg2:  '34. "Colors are the first to attract my attention in a picture"',
        msg3: '35. "I have a lot of dreams for the far future"',
        msg4: '36. "I always summarize learning materials into diagrams"',
        msg5: '37. "I prefer to work in theoretical fields"',
        msg6: '38. "I do the work best if it is related to what is read or written"',
        msg7: '39. " I listen a lot to my feelings"',
        msg8: '40. "The sound of a baby cry irritates me very much"',
    },
    {
        msg1:  '41. "I am interested in knowing the function of things"',
        msg2:  '42. "People see me innovative in creating new ideas"',
        msg3: '43. "I tend to repeat a word loudly to learn how to write it correctly"',
        msg4: '44. "It is important to show your feelings"',
        msg5: '45. "I always make comparisons"',
        msg6: '46. "While doing something new, I never give up till succeed"',
        msg7: '47. "When I try to remember something I hear its description as if someone is speaking in my mind"',
        msg8: '48. "I take my decisions in an objective and systematic way"',
    }
  ];
