import React from "react";
import classes from "./style/sidebar.module.css";
import SortIcon from "@mui/icons-material/Sort";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import LogoutIcon from "@mui/icons-material/Logout";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { NavLink } from "react-router-dom";

const CompanySideBarx = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const signOutHandeler = () => {
    sessionStorage.removeItem("tokenValue");
    sessionStorage.removeItem("ID");
    dispatch({ type: "signOut" });
    history.push("/");
  };

  const [active, setActive] = useState("sleepOptions");
  const navHandeler = () => {
    setActive("optionsActive");
  };
  const closeNav = () => {
    setActive("sleepOptions");
  };

  return (
    <div
      className={active === "optionsActive" ? classes.expand : classes.sidebar}
    >
      {
        <div className={classes.navOptions}>
          <div className={`${classes[active]} ${classes.options}`}>
            <ul className={classes.links}>
              <li onClick={closeNav}>
                <NavLink
                  to="/CompanyView/Profile"
                  activeClassName={
                    active === "optionsActive"
                      ? classes.activeLink
                      : classes.activeShrink
                  }
                  className={classes.op}
                >
                  <AccountBoxIcon className={classes.icon} fontSize="large" />

                  <p
                    className={
                      active === "optionsActive"
                        ? classes.appear
                        : classes.disappear
                    }
                  >
                    Profile
                  </p>
                </NavLink>
              </li>
              <li onClick={closeNav}>
                <NavLink
                  to="/CompanyView/Users"
                  activeClassName={
                    active === "optionsActive"
                      ? classes.activeLink
                      : classes.activeShrink
                  }
                  className={classes.op}
                >
                  <SortIcon className={classes.icon} fontSize="large" />

                  <p
                    className={
                      active === "optionsActive"
                        ? classes.appear
                        : classes.disappear
                    }
                  >
                    Users
                  </p>
                </NavLink>
              </li>
              <li onClick={closeNav}>
                <NavLink
                  to="/CompanyView/findboss"
                  activeClassName={
                    active === "optionsActive"
                      ? classes.activeLink
                      : classes.activeShrink
                  }
                  className={classes.op}
                >
                  <RecentActorsIcon className={classes.icon} fontSize="large" />
                  <p
                    className={
                      active === "optionsActive"
                        ? classes.appear
                        : classes.disappear
                    }
                  >
                    Find A Boss
                  </p>
                </NavLink>
              </li>
              <li onClick={closeNav}>
                <NavLink
                  to="/CompanyView/jobmatch"
                  activeClassName={
                    active === "optionsActive"
                      ? classes.activeLink
                      : classes.activeShrink
                  }
                  className={classes.op}
                >
                  <PersonSearchIcon className={classes.icon} fontSize="large" />
                  <p
                    className={
                      active === "optionsActive"
                        ? classes.appear
                        : classes.disappear
                    }
                  >
                    Job Match
                  </p>
                </NavLink>
              </li>
              <li onClick={signOutHandeler}>
                <a className={classes.op}>
                  <LogoutIcon className={classes.icon} fontSize="large" />
                  <p
                    className={
                      active === "optionsActive"
                        ? classes.appear
                        : classes.disappear
                    }
                  >
                    Log Out
                  </p>
                </a>
              </li>
            </ul>
          </div>
        </div>
      }
      {active === "sleepOptions" ? null : (
        <div className={classes.back} onClick={navHandeler}></div>
      )}
      <div className={classes.bur}>
        {active === "sleepOptions" ? (
          <ArrowForwardIosIcon
            onClick={navHandeler}
            className={classes.arrow}
          />
        ) : (
          <ArrowBackIosNewIcon onClick={closeNav} className={classes.arrow} />
        )}
      </div>
    </div>
  );
};

export default CompanySideBarx;
