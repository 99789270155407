import classes from "./style/work.module.css"
import React from 'react' 
import img2 from '../Img/img2.jpg'

const Works = () => {
    return(
    <div className={classes.con}>
    <div className={classes.comp}>
        <h1 className={classes.titlea}>How it works ?</h1>
        <h2 className={classes.subtitle}>For Job Seekers & Self-Joining Applicants</h2>
        <div className={classes.work}>
            <img src={img2} alt="User2"></img>
        </div>
        <ul className={classes.cardlist}>
        <li className={classes.card}>
            <h1 className={classes.number}>1</h1>
            <div className={classes.par}>
            <p > Helps in discovering your talents and figuring out your uniqueness. </p>

            </div>
        </li>
        <li className={classes.card}>
            <h1 className={classes.number}>2</h1>
            <p className={classes.par}> Creates your “Talent Profile”; showing your work history, experiences, skills, education and it tells about your intrinsic personal competencies through your ISI Type. </p>
        </li>
        <li className={classes.card}>
            <h1 className={classes.number}>3</h1>
            <p className={classes.par}> Makes your data available for recruiters through an Applicant Tracking System (ATS). </p>
        </li>
        <li className={classes.card}>
            <h1 className={classes.number}>4</h1>
            <p className={classes.par}> Includes your data in Advanced Search for collaborating companies and organizations searching for candidates. </p>
        </li>
        <li className={classes.card}>
            <h1 className={classes.number}>5</h1>
            <p className={classes.par}> Serves as a link between you and hiring bodies and notify you with opportunities for further communication. </p>
        </li>
        </ul>
   
    </div>
    <div className={classes.diagonalbox1}>
            <div className={classes.content}> ... </div>
    </div>
    </div>
    );
}

export default Works