import React, { useState } from 'react'
import classes from './style/auth.module.css'
import {Formik,Form, Field, ErrorMessage } from 'formik'
import BForm from 'react-bootstrap/Form';
import { Blocks } from  'react-loader-spinner'
import axios from 'axios';
import * as Yup from 'yup'
import Pop from './Pop';
import Loader from './UI/Loader';
const Signup = (props) => {
const [state, setState] = useState(false)
const [resend, setResend] = useState(false)
const [errorMsg, setErrorMsg] = useState("");
const [isCompany , setIsCompany] = useState(false)
const [Spinner,setSpinner] = useState(false)

const closePop = ()=>{
    setState(false)
    setResend(false)
}
const initialValues = {
    email: '',
    password: '',
    ConfirmPassword:''
}

const validationSchema= Yup.object().shape({
    email: Yup.string().min(3).trim().email('Invalid email address').required(" Email field is required"),
    password: Yup.string().min(8).required("password field is required") ,
    ConfirmPassword: Yup.string().when("password", {
      is: val => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Both password need to be the same"
      )
    })
})
const onSubmit = (data,{ resetForm } )=>{
setErrorMsg("");
setSpinner(true);
const newData = {
    email: data.email,
    password: data.password
}
if(isCompany){
axios.post("https://matchmaker-77lp.onrender.com/api/company/signup", newData).then((res)=>{
    console.log(res)
    setState(true)
    setSpinner(false)
}).catch((err)=>{
    setSpinner(false)
    let val = err.response.data.message.search("E11000");
            console.log(val);
            if (val !== -1) {
                console.log(data)
                setErrorMsg("User Already Exists")
            }
    //setResend(true)
})
}else{
    axios.post("https://matchmaker-77lp.onrender.com/api/user/signup", data).then((res)=>{
    console.log(res)
    setState(true)
    setSpinner(false)
}).catch((err)=>{
    setSpinner(false)
    let val = err.response.data.message.search("E11000");
            console.log(val);
            if (val !== -1) {
                console.log(data)
                setErrorMsg("User Already Exists")
            }
    //setResend(true)
})
}
resetForm();

}
function clearError()
{
    setErrorMsg("")

}

  return (
    <div className={classes.container}>
        {
            state && 
            <Pop type="verify" closePop={closePop}/>
        }
        {
            resend && 
            <Pop type="err" closePop={closePop}/>
        }
        <div className={classes.center}>
        <div className={classes.box1}>
            <h1>Signup</h1>
            <BForm>
                <div key={`default-checkbox`} className="mb-3">
                    <BForm.Check 
                        type='checkbox'
                        id={`default-checkbox`}
                        label={`Signup as a company`}
                        onChange={(e)=>{
                            setIsCompany(e.target.checked)
                        }}
                    />
                </div>
            </BForm>
            <Formik initialValues={initialValues}  validationSchema={validationSchema} onSubmit={onSubmit}>
                <Form>
                    <div className={classes.fieldContainer}>
                        <Field className={classes.field} name='email' placeholder="Email address"  autoComplete= "off"/>
                        <label className={classes.label}> Email address</label>
                        <ErrorMessage name='email' component="span"/>
                    </div>
                    <div className={classes.fieldContainer}>
                        <Field   type="password" className={classes.field} name='password' placeholder="Password"  autoComplete= "off"/>
                        <label  className={classes.label}> Password</label>
                        <ErrorMessage name='password' component="span"/>
                    </div>
                    <div className={classes.fieldContainer}>
                        <Field  type="password" className={classes.field} name='ConfirmPassword' placeholder="ConfirmPassword"  autoComplete= "off" />
                        <label className={classes.label}> Confirm Password</label>
                        <ErrorMessage name='ConfirmPassword' component="span"/>
                    </div>
                    <div className={classes.errorMsg}>{errorMsg}</div>
                    <div className={classes.btn}>
                        <button onClick={clearError} type='submit'>Signup</button>
                    </div>
                </Form>
            </Formik>

        </div>
        <div className={classes.box2}>
        <p>Already have an account? <span onClick={props.signinHandeler}> Log In</span></p>
        {Spinner?<div className={classes.loader}> <Loader/> </div>:null}
        </div>
        </div>
    </div>
  )
}

export default Signup