import React from 'react'
import { useState, useEffect } from 'react'
import classes from './style/profile.module.css'
import SideBarProfile from '../components/SideBarProfile'
import EmptyProfile from '../components/EmptyProfile'
import FullProfile from '../components/FullProfile'
import axios from 'axios';
import AvatarProfile from '../components/AvatarProfile'
import Avatar from '../components/dymmyData'
import { useSelector} from 'react-redux'
import { useHistory } from "react-router-dom";
import { Switch, Route } from "react-router-dom"
import Spinner from '../components/UI/Spinner';

const Profile = () => {
  const history = useHistory();
  
  const Data=  useSelector(state => state);

  // this to redirect not authorized people
  useEffect(()=>{
    if(!Data.login){
      history.push("/");
    }
  },[Data])
  let token = sessionStorage.getItem("tokenValue");
  const [ProfileInfo,setProfileInfo ] = React.useState([]);
  const [completedProfile,setcompletedprofile] = useState(0);
  const [completedavatar1,setcompletedavatar1] = useState(0);
  const [completedavatar2,setcompletedavatar2] = useState(0);
  
  let test = completedavatar1;
  async function GetUserProfile() {
    var config = {
        method: 'get',
        headers: {Authorization:"Bearer "+token }
      };
    let response = '';
  try {
    response = await axios.get('https://matchmaker-77lp.onrender.com/api/user/'+sessionStorage.getItem("ID"),config).then((res) => res.data);

    setProfileInfo(response);
    setcompletedprofile(response.dataComplete);
    setcompletedavatar1(response.iRes1);
    setcompletedavatar2(response.iRes2);
    setSpinner(false)

    return (response);
  } catch (error) {
    if (error.response) {
      return (error.response);
    }
  }
  return (response);
}

React.useEffect(() => {
  (async () => {
    const resp = await GetUserProfile();
    setProfileInfo(resp);
  })();
}, []);


const[spinner,setSpinner] = useState(true)

  return (
    <div className={classes.Profile}>
        <SideBarProfile />
        <Switch>
          <Route path='/Profile/myprofile'>
            {spinner?<Spinner/>:<>
            {completedProfile? <FullProfile 
              userName= {ProfileInfo.name}
              location = {ProfileInfo.country + ", "+ProfileInfo.address}
              jobPosition = {ProfileInfo.jobPosition}
              DateOfBirth = {ProfileInfo.dateOfBirth}
              NationalID = {ProfileInfo.nationalID}
              Email = {ProfileInfo.email}
              mobile = {ProfileInfo.mobileNO}
              HLOfEdu = {ProfileInfo.HLOfEdu}
              Faculty={ProfileInfo.faculty}
              certificates={ProfileInfo.certificates}
              internships={ProfileInfo.internships}
              yearsOfExp={ProfileInfo.yearsOfExp}
              otherf={ProfileInfo.otherf}
              job={ProfileInfo.job}
              longTermGoals={ProfileInfo.longTermGoals}
              Motivation={ProfileInfo.Motivation}
              generalSkills={ProfileInfo.generalSkills}
              technicalSkills={ProfileInfo.technicalSkills}
              pic = {ProfileInfo.pic}
              CV = {ProfileInfo.CV}
              view = {0}
            />:<EmptyProfile prof={0}/>}</>}
          
          </Route>
          <Route path='/Profile/Avatar'>
          {spinner?<Spinner/>:<>
          {completedavatar2? <AvatarProfile 
            avatar={Avatar[completedavatar1].img}
            avatarname={Avatar[completedavatar1].name}
            des={Avatar[completedavatar1].description}
            avatar2={Avatar[completedavatar2].img}
            avatarname2={Avatar[completedavatar2].name}
            des2={Avatar[completedavatar2].description}
            flt={ProfileInfo.flt}
            frt={ProfileInfo.frt}
            brt={ProfileInfo.brt}
            blt={ProfileInfo.blt}
            flp={ProfileInfo.flp}
            frp={ProfileInfo.frp}
            brp={ProfileInfo.brp}
            blp={ProfileInfo.blp}
            view = {0}
            />
            : <EmptyProfile prof={1}/>}</>}
          </Route>
          <Route path='/Profile/Bill'>
            <EmptyProfile prof={2}/>
          </Route>
        </Switch>
    </div>
  )
}

export default Profile