import React from 'react'
import { useState, useEffect } from 'react'
import FullProfile from './FullProfile'
import axios from 'axios';
import AvatarProfile from './ViewPersonality'
import Avatar from './dymmyData'
import Spinner from './UI/Spinner';
import classes from '../pages/style/profile.module.css'
import CompanySideBar from './CompanySideBar'
import ScrollToTop from "react-scroll-to-top";
import { useParams } from 'react-router-dom'
import SideBarProfile from './SideBarProfile';

const ViewProfile = () => {

  let token = sessionStorage.getItem("tokenValue");
  const [ProfileInfo,setProfileInfo ] = useState([]);
  const [completedavatar1,setcompletedavatar1] = useState(0);
  const [completedavatar2,setcompletedavatar2] = useState(0);

  const params = useParams()
  const type = sessionStorage.getItem("Type")
  
  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  async function GetUserProfile() {
    var config = {
        method: 'get',
        headers: {Authorization:"Bearer "+token }
      };
    let response = '';
  try {
    response = await axios.get('https://matchmaker-77lp.onrender.com/api/user/'+params.id,config).then((res) => res.data);

    setProfileInfo(response);
    setcompletedavatar1(response.iRes1);
    setcompletedavatar2(response.iRes2);
    setSpinner(false)

    return (response);
  } catch (error) {
    if (error.response) {
      return (error.response);
    }
  }
  return (response);
}

useEffect(() => {
  (async () => {
    const resp = await GetUserProfile();
    setProfileInfo(resp);
  })();
}, []);


const[spinner,setSpinner] = useState(true)

  return (
    <div className={classes.Profile}>
        <ScrollToTop className={classes.up} smooth/>
        {type=="Company"?<CompanySideBar/>:<SideBarProfile/>}
        <div className={classes.viewProfile}>
            {spinner?<Spinner/>:<>
            <h1 className={classes.title}>Profile Information</h1>
            <FullProfile 
                userName= {ProfileInfo.name}
                location = {ProfileInfo.country + ", "+ProfileInfo.address}
                jobPosition = {ProfileInfo.jobPosition}
                DateOfBirth = {ProfileInfo.dateOfBirth}
                NationalID = {ProfileInfo.nationalID}
                Email = {ProfileInfo.email}
                mobile = {ProfileInfo.mobileNO}
                HLOfEdu = {ProfileInfo.HLOfEdu}
                Faculty={ProfileInfo.faculty}
                certificates={ProfileInfo.certificates}
                internships={ProfileInfo.internships}
                yearsOfExp={ProfileInfo.yearsOfExp}
                otherf={ProfileInfo.otherf}
                job={ProfileInfo.job}
                longTermGoals={ProfileInfo.longTermGoals}
                Motivation={ProfileInfo.Motivation}
                generalSkills={ProfileInfo.generalSkills}
                technicalSkills={ProfileInfo.technicalSkills}
                pic = {ProfileInfo.pic}
                CV = {ProfileInfo.CV}
                view = {1}
            /></>}
            
            {spinner?<Spinner/>:<>
            <h1 className={classes.title}>Personality Details</h1>
            <AvatarProfile 
                avatar={Avatar[completedavatar1].img}
                avatarname={Avatar[completedavatar1].name}
                des={Avatar[completedavatar1].description}
                avatar2={Avatar[completedavatar2].img}
                avatarname2={Avatar[completedavatar2].name}
                des2={Avatar[completedavatar2].description}
                flt={ProfileInfo.flt}
                frt={ProfileInfo.frt}
                brt={ProfileInfo.brt}
                blt={ProfileInfo.blt}
                flp={ProfileInfo.flp}
                frp={ProfileInfo.frp}
                brp={ProfileInfo.brp}
                blp={ProfileInfo.blp}
                view = {1}
                /></>}
        </div>
    </div>
  )
}

export default ViewProfile
