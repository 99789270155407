import React from 'react'
import { useState, useEffect } from 'react'
import Login from '../components/Login'
import ForgotPass from '../components/ForgotPassword'
import ResetForm from '../components/ResetForm'
import Signup from '../components/Signup'
import Spinner from '../components/UI/Spinner'

const Auth = (props) => {
  const [haveAcount, setHaveAcount] = useState(true);
  const [resetPass, setResetPass] = useState(false);
  const [forgetPass, setForgetPass] = useState(false);
  const [spinner, setSpinner] = useState(true)
  function delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
  }

  async function fix() {
    await delay(1000);
    setSpinner(false)

  }
  useEffect(() => {
    fix()
    if(props.resetPass){
      setResetPass(true)
    }
  },[props.resetPass])


  const signinHandeler = () => {
    setHaveAcount(true)
    setForgetPass(false)
  }
  const signupHandeler = () => {
    setHaveAcount(false)
  }
  function forgetPassHandler() {
    setForgetPass(true);
  }

  return (
    <>
      {
        spinner ? <Spinner /> :
          !resetPass ?
            !(forgetPass) ?
              <div>
                {
                  haveAcount ? <Login forgetPassHandler={forgetPassHandler} signupHandeler={signupHandeler} /> : <Signup signinHandeler={signinHandeler} />
                }
              </div> :
              <div>
                <ForgotPass signinHandeler={signinHandeler} />
              </div>
            :
            <ResetForm/>
            
        }

    </>
  )
}

export default Auth